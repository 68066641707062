<template>
  <footer id="footer">
    <section class="footer-header footer-blue">
      <div class="icon-wrapper">
        <router-link :to="{name:'HowItWork', hash:'#granted-fresh'}" @click.native="scrollToSection('granted-fresh')">
          <div class="icon-container">
            <img v-lazy="fresh" alt="Icone fraicheur">
            <div class="icon-container-text">
              <h2>{{ $t('fresh') }}</h2>
              <p>Respect de la chaîne du froid</p>
            </div>
          </div>
        </router-link>
        <router-link :to="{name:'HowItWork', hash:'#express-delivery'}" @click.native="scrollToSection('express-delivery')">
          <div class="icon-container">
            <img v-lazy="delivery" alt="Icone expedition">
            <div class="icon-container-text">
              <h2>{{ $t('expedition') }}</h2>
              <p>24h en France métropolitaine </p>
            </div>
          </div>
        </router-link>
        <router-link :to="{name:'CommitmentFB'}">
          <div class="icon-container">
            <img v-lazy="security" alt="Icone sécurité">
            <div class="icon-container-text">
              <h2>{{ $t('security') }}</h2>
              <p>Paiement 100% sécurisé</p>
            </div>
          </div>
        </router-link>
        <router-link :to="{name:'CommitmentFB'}">
          <div class="icon-container">
            <img v-lazy="satisfaction" alt="Icone de satifaction">
            <div class="icon-container-text">
              <h2>{{ $t('satisfaction') }}</h2>
              <p>4,8 sur 5</p>
            </div>
          </div>
        </router-link>
        <router-link :to="{name:'ContactUs'}">
          <div class="icon-container">
            <img v-lazy="phone" alt="Icone de contact">
            <div class="icon-container-text">
              <h2>{{ $t('question') }}</h2>
              <p>02 99 89 48 16</p>
            </div>
          </div>
        </router-link>


      </div>
    </section>
    <section class="footer-body">
      <!-- TODO may create a newsletter component -->
      <article class="newsletter-container">
        <h3>Inscrivez vous à notre newsletter</h3>
        <form class="newsletter" @submit.prevent="subscribeNewsletter">
          <input type="email" v-model="newsletter" placeholder="Entrez votre adresse mail" required>
          <input type="submit" value=">">
        </form>
      </article>

      <div class="network-container">
        <h3>Suivez-nous sur les réseaux !</h3>
        <nav class="social-network">
          <a aria-label="Lien Facebook" href="https://www.facebook.com/FamilleBoutrais/" target="_blank"
             rel="noopener noreferrer">
            <font-awesome-icon :icon="['fab', 'facebook-f']" size="xl"/>
          </a>
          <a aria-label="Lien Instagram" href="https://www.instagram.com/familleboutrais/" target="_blank"
             rel="noopener noreferrer">
            <font-awesome-icon :icon="['fab', 'instagram']" size="xl"/>
          </a>
          <a aria-label="Lien linkedin" href="https://fr.linkedin.com/company/la-famille-boutrais" target="_blank"
             rel="noopener noreferrer">
            <font-awesome-icon :icon="['fab', 'linkedin-in']" size="xl"/>
          </a>
          <a aria-label="Vidéo youtube chrono" href="https://www.youtube.com/channel/UCucrvdX1kKKyeqKEPwPAbsA"
             target="_blank"
             rel="noopener noreferrer">
            <font-awesome-icon :icon="['fab', 'youtube']" size="xl"/>
          </a>
        </nav>
      </div>
    </section>
    <section class="footer-footer footer-blue">
      <div class="footer-column footer-logo">
        <router-link class="inactive-link" exact-active-class="active-link"
                     :to="{name: 'Home'}">
          <img class="footer-logo" alt="logo groupe La Famille Boutrais"
               v-lazy="logoFamilleBoutraisGris">
        </router-link>

      </div>
      <nav class="footer-column footer-list">
        <ul>
          <li><h2>La famille Boutrais</h2></li>
          <li>
            <h3>
              <router-link :to="{name: 'Blog'}">{{ $t('news') }}</router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link :to="{name: 'KnowHow'}">{{ $t('know_how') }}</router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link :to="{name: 'LegalNotice'}">{{ $t('legal_notice') }}</router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link :to="{name: 'GeneralConditionsSale'}">{{ $t('gcs') }}</router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link :to="{name: 'ContactUs'}">{{ $t('contact_us') }}</router-link>
            </h3>
          </li>
        </ul>
      </nav>
      <nav class="footer-column footer-list">
        <ul>
          <li><h2>{{ $t('product') }}</h2></li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link"
                           :to="{name: 'Shop', params: {category: 'huître-en-ligne'}}">
                {{ $t('oyster_categ') }}
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link"
                           :to="{name: 'Shop', params: {category: 'crustacé-en-ligne'}}">
                {{ $t('crustacean_categ') }}
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link"
                           :to="{name: 'Shop', params: {category: 'coquillage-en-ligne'}}">
                {{ $t('shellfish_categ') }}
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link"
                           :to="{name: 'Shop', params: {category: 'epicerie-en-ligne'}}">
                {{ $t('grocery_categ') }}
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link"
                           :to="{name: 'Shop', params: {category: 'vin-en-ligne'}}">
                {{ $t('cellar_categ') }}
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link"
                           :to="{name: 'Shop', params: {category: 'coffret'}}">
                {{ $t('boxes_categ') }}
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link"
                           :to="{name: 'Shop', params: {category: 'accessoires'}}">
                {{ $t('accessory_categ') }}
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link"
                           :to="{name:'Gift'}">{{ $t('gift') }}
              </router-link>
            </h3>
          </li>
        </ul>
      </nav>
      <nav class="footer-column footer-list">
        <ul>
          <li><h2>{{ $t('about') }}</h2></li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link" :to="{name: 'HowItWork'}">Comment ça
                marche ?
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link" :to="{name: 'WhoAreWe'}">Qui
                sommes-nous
                ?
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link" :to="{name: 'CommitmentFB'}">Nos
                engagements
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link" :to="{name: 'PointsOfSales'}">Nos
                points
                de vente
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link class="inactive-link" exact-active-class="active-link" :to="{name: 'Services'}">Nos
                prestations
              </router-link>
            </h3>
          </li>
          <li>
            <h3>
              <router-link
                  to="#">

              </router-link>
            </h3>
          </li>
        </ul>
      </nav>
      <div class="footer-column footer-partners">
        <div>
          <h2>{{ $t('support') }}</h2>
          <ul class="footer-list">
            <li class="partner">
              <a aria-label="Site web ue" href="https://european-union.europa.eu/index_fr" target="_blank">
                <img v-lazy="EUROPE_1" alt="Logo Europe">
              </a>
            </li>
            <li class="partner">
              <a aria-label="Site web region bretagne" href="https://www.bretagne.bzh/" target="_blank">
                <img v-lazy="BRETAGNE_1" alt="Logo region Bretagne">
              </a>
            </li>
            <li class="partner">
              <a aria-label="Site web UE" href="https://www.europe-en-france.gouv.fr/" target="_blank">
                <img v-lazy="FEAMP_1" alt="Logo Feamp">
              </a>
            </li>
            <li class="partner">
              <a aria-label="Site web Chronofresh" href="https://www.chronofresh.fr/" target="_blank">
                <img v-lazy="chronofresh_logo1" alt="Logo Chronofresh">
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <div class="credits footer-blue">
      <p>La Famille Boutrais All Right Reserved</p>
    </div>
  </footer>
</template>

<script>

import {getAPI} from "@/services/axios-api";
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faFacebookF, faInstagram, faLinkedinIn, faYoutube} from "@fortawesome/free-brands-svg-icons";
import fresh from '@/assets-2/footer/fresh.svg'
import delivery from '@/assets-2/footer/delivery.svg'
import security from '@/assets-2/footer/security.svg'
import satisfaction from '@/assets-2/footer/satisfaction.svg'
import phone from '@/assets-2/footer/phone.svg'
import logoFamilleBoutraisGris from '@/assets/footer/logo-famille-boutrais-gris-footer.png'
import EUROPE_1 from '@/assets/footer/EUROPE_1.png'
import BRETAGNE_1 from '@/assets/footer/BRETAGNE_1.png'
import FEAMP_1 from '@/assets/footer/FEAMP_1.png'
import chronofresh_logo1 from '@/assets/footer/chronofresh_logo1.png'

library.add(faFacebookF, faInstagram, faLinkedinIn, faYoutube)

export default {
  name: "Footer",
  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },
  data() {
    return {
      fresh,
      delivery,
      security,
      satisfaction,
      phone,
      logoFamilleBoutraisGris,
      EUROPE_1,
      BRETAGNE_1,
      FEAMP_1,
      chronofresh_logo1,
      newsletter: '',
    }
  },
  methods: {
    subscribeNewsletter: async function () {

      await getAPI.post('/account/newsletter/', {'email': this.newsletter})
          .then(res => {
            this.newsletter = ''
            this.$toast.open({
              message: 'Vous avez été ajouté à la Newsletter avec succès',
              type: 'info',
            });
          })
          .catch(res => {
            console.log(res)
          })
    },
    scrollToSection(id) {
      let element = document.getElementById(id);
      if (element) element.scrollIntoView({ behavior: 'smooth' });
    },
  }
}
</script>

<style lang="scss" scoped>
footer {
  z-index: 0;
  margin-top: 5vh;
}

.footer-blue {
  background-color: $Blue;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 100%;
}

.footer-header {
  @include flex(center, center);
  width: 100%;
  padding: 5%;
  position: relative;
  border-bottom: solid 4px $Brown;

  @media screen and (min-width: 768px) {
    padding: 2% 7.5%;
    border-width: 2px;
  }
  @media screen and (max-width: 575.99px) {
    padding: 4vh 4vw
  }

  .icon-wrapper {
    @include flex(space-between, center, row, wrap, $gap: clamp(15px, 1.5vw, 40px));
    user-select: auto;

    @media screen and (max-width: 575.99px) {
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: nowrap;
      margin: 0 auto;
      gap: 3vh
    }
    @media screen and (min-width: 576px) {
      width: 100%;
    }
    @media screen and (min-width: 576px) and (max-width: 991.99px) {
      justify-content: center;
    }

    a {
      text-decoration: none;
    }

    .icon-container {
      @include flex(flex-start, center, $gap: .5rem);
      color: $White;
      @media screen and (min-width: 576px) and (max-width: 991.99px) {
        justify-content: center;
        flex: 0 0 30%;
      }
      @media screen and (max-width: 575.99px) {
        flex: 0 0 auto;
        gap: 3vw
      }

      img {
        @include fixed-size(clamp(50px, 4vw, 150px));
        //max-height: 50px;
        //height: auto;
        //object-fit: contain;
        //@media screen and (max-width: 575.99px) {
        //	@include fixed-size(50px);
        //}
      }

      &-text {
        @include flex(flex-start, flex-start, column);
        color: $White;

        h2 {
          font-weight: 600;
          font-size: 1.1rem;
          padding: 0;
          white-space: nowrap;
          margin-top: .25rem;
        }

        p {
          margin-top: .25rem;
          font-size: 13px;
        }
      }
    }
  }
}

.footer-body {
  @include flex(center, center, column, wrap, $gap: 3vh);
  width: 100%;
  padding: 5%;
  position: relative;
  border-bottom: solid 4px $Brown;

  @media screen and (min-width: 768px) {
    padding: 2% 7.5%;
    border-width: 2px;
  }

  @media screen and (min-width: 576px) {
    flex-direction: row;
    justify-content: space-around;
    gap: 5vw
  }

  h3 {
    color: $Black;
    font-size: 1rem;
    font-weight: bold;
  }

  .newsletter-container {
    @include flex(center, center, column, $gap: 10px);

    .newsletter {
      @include flex(center, center);
      position: relative;
      width: 100%;
      height: 40px;
      overflow: hidden;

      input {
        appearance: none;
        border: none;
        line-height: 1;
        height: 100%;
        outline: none;
        text-overflow: ellipsis;
        transition: .3s;

        &[type="email"] {
          width: 100%;
          padding: 5px 10px;
          border-radius: 7px 0 0 7px;
          box-shadow: 0 0 0 2px inset $Brown;

          &::placeholder {
            text-align: center;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-size: 10px;
          }

          &:focus {
            box-shadow: 0 0 0 4px inset $Brown;
          }
        }

        &[type="submit"] {
          background-color: $Brown;
          aspect-ratio: 1;
          color: $White;
          cursor: pointer;
          border-radius: 0 7px 7px 0;
          margin-left: -2px;

          &:hover, &:focus {
            opacity: .7;
            box-shadow: 0 0 0 20px inset $LightBrown;
          }
        }
      }

      span {
        vertical-align: middle;
        display: block;
        height: 100%;
        background-color: $Brown;
        border-radius: 0;
        text-align: center;
        color: $White;
        @include fixed-size(50px)
      }
    }
  }

  .network-container {
    @include flex(center, center, column, $gap: 10px);

    .social-network {
      @include flex(center, center, row, wrap, $gap: 20px);

      a {
        transition: .2s;

        &:hover {
          opacity: .7;
        }

        svg {
          @include fixed-size(30px);

          path {
            fill: $Brown;
          }
        }
      }
    }
  }
}

.footer-footer {
  @include flex(center, flex-start, column, wrap, $gap: 4vh);
  width: 100%;
  padding: 2%;
  position: relative;
  color: $White;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }

  h2 {
    text-align: left;
    font-weight: bold;
    margin-bottom: .5vh;
    font-size: 22px;
    @media screen and (min-width: 992px) {
      font-size: 18px;
    }
  }

  .footer-column {
    flex: 0 0 100%;
    margin: 0 auto;
    width: 60%;

    @media screen and (min-width: 992px) {
      margin: 0;
      width: auto;
      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (min-width: 992px) and (max-width: 1199.99px) {
      &.footer-logo {
        flex: 0 0 20%;
      }
      &.footer-list {
        flex: 0 0 18%;
      }
      &.footer-partners {
        flex: 0 0 20%;
      }
    }

    @media screen and (min-width: 1200px) {
      &.footer-logo {
        flex: 0 0 15%;
      }
      &.footer-list {
        flex: 0 0 15%;
      }
      &.footer-partners {
        flex: 0 0 25%;
      }
    }
  }

  .footer-logo {
    align-self: center;

    a {
      display: block;

      img {
        width: 50vw;
        max-width: 300px;
        height: auto;
        object-fit: contain;
        @media screen and (min-width: 992px) {
          max-width: 100%;
        }
      }
    }
  }

  .footer-list ul {
    @include flex(center, flex-start, column, wrap, $gap: 10px);

    h3 {
      text-decoration: none;
      transition: .2s;
      font-weight: normal;
      font-size: 17px;
      text-align: left;
      @media screen and (min-width: 992px) {
        font-size: 14px;
      }

      a {
        color: $White;
        text-decoration: none;
        transition: .2s;

        &:hover {
          opacity: .7;
        }
      }
    }
  }

  .footer-partners > div {
    @include flex(flex-start, center, column, $gap: .75vh);
    @media screen and (min-width: 992px) {
      align-items: flex-start;
    }

    .footer-list {
      @include flex(space-between, center, row, wrap, $gap: 10px);

      .partner {
        flex: 0 0 30%;
        aspect-ratio: 1;

        a {
          display: flex;
          opacity: 1 !important;

          img {
            object-fit: contain;
            width: 100%;
            filter: grayscale(1);
            transition: .2s;
          }

          &:hover img {
            filter: grayscale(0);
          }
        }
      }
    }
  }
}

.credits {
  width: 100%;
  border-top: solid $White 2px;
  font-size: 14px;
  padding: 1% 5%;
  text-align: left;
  color: $White;
  font-weight: 600;

  @media screen and (max-width: 991.99px) {
    text-align: center;
  }
}
</style>